import "./Datasheet.css"
import { Tooltip } from 'react-tooltip'
import {useLoaderData} from "react-router-dom";
import {roster_loader} from "./RosterDisplay";


export async function unit_loader({params}) {
  const roster = await roster_loader({params});
  const unit = roster.getUnit(params.unit_id)
  return unit;
}


function discoverByType(obj, type){
  const t = typeof obj
  if (t !== 'object' || t === undefined)
    return []
  const values = Object.values(obj)
  const match = values.filter(val => val && (val.type ===type || val.typeName ===type))
  const children = values.map(val => discoverByType(val, type))
  return [...match, ...children].flat()
}


export function UnitDisplay(){
  const unit = useLoaderData()
  if (unit.type === "join_group")
    return (
      <span>
        {unit.units.map(u => <Datasheet unit={u}/>)}
      </span>
    )
  else
    return (
      <Datasheet unit={unit}/>
    )
}

export default function Datasheet({unit}){
  if (unit === null)
    return (<p>Loading...</p>)
  const faction_style = 'sororitas'

  const models = [...discoverByType([unit], "model")]
  const stats = discoverByType(unit, "Unit")

  const rules = unit.rules

  let abilities = unit.profiles.filter(p => p.typeName === 'Abilities')
  const raw_invul = abilities.filter(a => a.name.includes("Invulnerable Save"))
  abilities = abilities.filter(a => !a.name.includes("Invulnerable Save"))
  abilities = abilities.filter(a => a.name !== "Leader")
  if (raw_invul[0]){
    const raw_invul_text = raw_invul[0].characteristics[0].$text
    const regex =  /\d+\+/
    const invul = regex.exec(raw_invul_text);
    stats.forEach(s => s.characteristics.push({name: "inv", $text: invul[0]}))
  }

  const weapons = models.map(m => m.selections).flat()

  return (
    <div key={unit.id} className={"UnitDatasheet " + faction_style}>
      <div className="datasheetHeader">
        <div>
          <header className="datasheetName"> {unit.name} </header>
          <UnitProfile models={stats}/>
        </div>
      </div>
      <AbilityList abilities={abilities} rules={rules}/>
      <WeaponsList weapons={weapons}/>
      <p className="dsUnitKeywords"><b>Keywords:</b> {unit.categories.map(c => c.name).join(', ')}</p>
    </div>
  );
}


function UnitProfile({models}) {
  const keys = ["m", "t", "sv", 'inv', "w", "ld", "oc"]
  const all_equal = models.every( v =>
    JSON.stringify(v.characteristics) === JSON.stringify(models[0].characteristics)
  )
  const all_stats = all_equal ? [models[0]] : models

  return(
    <div className="datasheetStats">
      <table>
        <thead><tr>{keys.map(key => <th> {key} </th>)}</tr></thead>
        <tbody>{all_stats.map( model => <ModelStats keys={keys} model={model} doShowName={!all_equal}/>)}</tbody>
      </table>
    </div>
  )
}

function ModelStats({model, keys, doShowName}){
  const stats = {}
  for (let c of model.characteristics){
    stats[c.name.toLowerCase()] = c.$text
  }

  return (
    <tr>
      {keys.map( key =>
        <td>
          <div className="dsCharFrame">{stats[key]} </div>
        </td>
      )}
      {doShowName && <td>{model.name}</td>}
    </tr>
  )
}


function WeaponsList({weapons}){
  const ranged_keys = ["name", "range", "a", "bs", "s", "ap", "d"]
  const melee_keys = ["name", "range", "a", "ws", "s", "ap", "d"]

  const ranged_headers = ["RANGED", "range",  "a", "bs", "s", "ap", "d"]
  const melee_headers = ["MELEE",  "", "a", "ws", "s", "ap", "d"]

  weapons = weapons.map(w=> w.selections ? w.selections : w).flat().filter(w => w.profiles)

  weapons.forEach(w => w.profiles.forEach(p => {p.rules = w.rules; p.number = w.number}))
  let profiles = weapons.map(w => w.profiles).flat()

  profiles = profiles.reduce((acc, p) => {
      const existing = acc.find(p1 => p.id === p1.id)
      if (existing) {
        existing.number += p.number
      }
      else{
        acc.push(p)
      }
      return acc
    }, [])
  const melee_profiles = profiles.filter(p => p.typeName === "Melee Weapons")
  const ranged_profiles = profiles.filter(p => p.typeName === "Ranged Weapons")

  return(
    <div>
      <table className="dsWeaponTable">
        {ranged_profiles.length > 0 && <thead ><tr>{ranged_headers.map(key => <th className="dsWeaponHeader"> {key} </th>)}</tr></thead>}
        <tbody>{ranged_profiles.map( profile => <WeaponStats keys={ranged_keys} profile={profile}/>)}</tbody>
        {melee_profiles.length > 0 && <thead><tr>{melee_headers.map(key => <th className="dsWeaponHeader"> {key} </th>)}</tr></thead>}
        <tbody>{melee_profiles.map( profile => <WeaponStats keys={melee_keys} profile={profile}/>)}</tbody>
      </table>
    </div>
  )
}

function WeaponAbility({rule}){
  const tooltip_id = `weapon_kw_tooltip-${rule.name}`

  if (rule.description)
    return(
      <div className="dsWeaponKeywordTooltip">
        <a  data-tooltip-id={tooltip_id} data-tooltip-place="top"
           data-tooltip-content={rule.description.description}>
          <Tooltip style={{width: "90%", fontSize: "var(--normal-font-size)"}} id={tooltip_id} />
          &nbsp;[<span>{rule.name}{rule.parameter && ` ${rule.parameter}`}</span>]
        </a>
      </div>
    )
  else
    return(
      <div className="dsWeaponKeywordTooltip">
        &nbsp;[<span>{rule.name}{rule.parameter && ` ${rule.parameter}`}</span>]
      </div>
    )
}


function WeaponStats({profile, keys}){
  const stats = {}
  for (let c of profile.characteristics){
    stats[c.name.toLowerCase()] = c.$text
  }
  const num = profile.number > 1 ? `${profile.number}x ` : ''

  const raw_keywords = stats.keywords ? stats.keywords.split(', ') : []
  const keywords = raw_keywords.filter(k => k !== '-').map(k => {
    return {
      name: k,
      description: profile.rules ? profile.rules.find(p => k.includes(p.name)) : undefined
    }
  })

  return (
    <tr className="dsWeaponRow">
      {keys.map( key =>
        key==='name' ?
          <td className="dsWeaponCell">
            <div className="dsWeaponCellContent">
              <div className="dsWeaponName"> {num}{profile.name} </div>
              {keywords && keywords.map(rule => <WeaponAbility rule={rule}/>)}
            </div>
          </td>
          :
          <td className="dsWeaponCell">
            <div className="dsWeaponName"> {stats[key]} </div>
            </td>

      )}
    </tr>
  )
}


function AbilityList({rules, abilities}){
  return (
    <div className="dsAbilities">
      <div className="dsHorAbilityList">
        {rules && rules.map(a => <RuleTooltip rule={a}/>)}
      </div>
      {abilities.map(a =>
        <div className="dsAbilityCard">
          <p className="dsAbilityHeader">{a.name}{a.parameter && ` ${a.parameter}`}:</p>
          <p className="dsAbilityBody">
            {a.characteristics[0].$text}
          </p>
        </div>
      )}
    </div>
  )
}

function RuleTooltip({rule}) {
   const description_html = `<p>${rule.description.replaceAll('\n', '<br/>')}<p>`
   return(
      <div className="dsAbilityTooltip">
        <a data-tooltip-id="my-tooltip" data-tooltip-place="top"
           data-tooltip-html={rule.description ? description_html : ""}>
          <p className="dsAbilityHeader"> {rule.name}{rule.parameter && ` ${rule.parameter}`}</p>
        </a>
        <Tooltip style={{width: "90%", fontSize: "var(--normal-font-size)"}} id="my-tooltip"/>
      </div>
   )
}
