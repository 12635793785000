import "./Stratagems.css"
import {useState} from "react";
import {phaseIcons} from "../repository/pictures";
import {useLoaderData} from "react-router-dom";
import {roster_loader} from "./RosterDisplay";
import {API} from "../api";


async function core_stratagems_loader() {
  return await API.get_core_stratagems()
}

async function detachment_stratagems_loader(detachment_name) {
  const [detachment] = await API.get_detachment(detachment_name)
  return detachment
}

export async function stratagems_loader({params}){
  const roster = await roster_loader({params});
  const detachment = roster.getDetachment()

  const core_stratagems = await core_stratagems_loader();
  const detachment_stratagems = await detachment_stratagems_loader(detachment.name);

  return {detachment, detachment_stratagems, core_stratagems}
}


export default function StratagemsView(){
  const {detachment, detachment_stratagems, core_stratagems} = useLoaderData();

  return (
    <div>
      <h2>{detachment.name}</h2>
      {detachment.rules.map(r => <DetachmentRule rule={r}/>)}
      {detachment_stratagems.stratagems.map(s=> <Stratagem stratagem={s}/>)}
      <h3>Core</h3>
      {core_stratagems.map(s=> <Stratagem stratagem={s}/>)}
    </div>
  )
}


function DetachmentRule({rule}){
  return(
    <div>
      <h3>{rule.name}</h3>
      <p>{rule.description}</p>
    </div>
  )
}


function Stratagem({stratagem}){
  const [collapsed, setCollapsed] = useState(true);

  const onHideClick = () =>{
    setCollapsed(!collapsed)
  }

  const turnClass = `${stratagem.turn}Turn`

  return (
    <div className={`stratagemCard ${turnClass}`}>
      <div className="stratagemHeader" onClick={onHideClick}>
        <div className="stratagemIcons">{stratagem.phases.map( p=> <img src={phaseIcons[p]}/>)}</div>
        <h2>{stratagem.name} </h2>
        <div className="stratagemCost"> <h2>{stratagem.cost}</h2></div>
      </div>
      <div className={`stratagemBody ${turnClass}` + (collapsed ? " hidden" : "")}>
        {stratagem.when && <p><b>When:</b> {stratagem.when}</p>}
        {stratagem.target && <p><b>Target:</b> {stratagem.target}</p>}
        {stratagem.effect && <p><b>Effect:</b> {stratagem.effect}</p>}
        {stratagem.restrictions && <p><b>Restrictions:</b> {stratagem.restrictions}</p>}
      </div>
    </div>
  )
}
