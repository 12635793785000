import {useState} from "react";
import "./Auth.css"
import {useNavigate} from "react-router-dom";
import {Auth} from "../auth";
import {API} from "../api";


export function LoginPage(){
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    return await Auth.sign_in(username, password)
      .then(r =>
        navigate("/"))
      .catch(err =>
        alert(`Auth failed (): ${err}`)
      )
  };

  return (
    <div className="AuthContainer">
      <form onSubmit={handleLogin} className="AuthForm">
        <div className="AuthField">
          <label htmlFor="username">Username:</label>
          <input
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="AuthField">
          <label htmlFor="password">Password:</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="AuthButton">Login</button>
      </form>
      <button className="AuthButton" onClick={() => navigate("/sign_up")}>Sign Up</button>
    </div>
  );
}


export function RegisterPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  let navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm){
      alert(`Registration failed: Passwords mismatch`)
    }
    else {
      return await API.sign_up(username, password)
        .then(r =>
          navigate("/login"))
        .catch(err =>
          alert(`Registration failed (): ${err}`)
        )
    }
  };

  return (
    <div className="AuthContainer">
      <form onSubmit={handleRegister} className="RegisterForm">
        <div className="AuthField">
          <label htmlFor="username">Username:</label>
          <input
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="AuthField">
          <label htmlFor="password">Password:</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="AuthField">
          <label htmlFor="password">Password, again:</label>
          <input
            id="password_confirm"
            type="password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </div>
        <button type="submit" className="AuthButton">Save</button>
      </form>
    </div>
  );
}
