import './App.css';

import { Outlet } from "react-router-dom";
import React from "react";
import {createTheme, ThemeProvider} from "@mui/material";


const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#282c34",
      light: '#53565c',
      dark: '#1c1e24',
      contrastText: '#fff',
    },
    secondary: {
      main: "#301113",
      light: "#561113"
    }
  }
});


export default function App() {
  return(
    <div className="App">
      <ThemeProvider theme={theme}>
        <Outlet/>
      </ThemeProvider>
    </div>
  )
}
