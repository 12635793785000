export class Roster{
  constructor(data) {
    this.data = data
  }

  getAllUnits(){
    return this.data.roster.forces[0].selections.filter(s => ['unit', 'model', 'join_group'].includes(s.type))
  }

  getUnit(unit_id){
    return this.data.roster.forces[0].selections.find(unit => unit.id === unit_id);
  }

  getId(){
    return this.data.id
  }

  getDetachment(){
    return this.data.roster.forces[0].selections.filter(s => s.name.includes("Detachment"))[0].selections[0]
  }

  joinUnits(join_items){
    const join_units = join_items.filter(i => i.type !== "join_group")
    const join_groups = join_items.filter(i => i.type === "join_group")
    const group_name = join_items.map(i => i.name).join(', ')
    const roster_selections = this.data.roster.forces[0].selections
    const join_ids = join_items.map(i => i.id)


    this.data.roster.forces[0].selections = roster_selections.filter(s => !join_ids.includes(s.id))
    this.data.roster.forces[0].selections.push({
      name: group_name,
      id: "group_"+Date.now().toString(),
      type: "join_group",
      units: [...join_units, ...join_groups.map(g => g.units).flat()],
    })
  }

  splitGroup(group){
    this.data.roster.forces[0].selections = this.data.roster.forces[0].selections.filter(s => group.id !== s.id)
    this.data.roster.forces[0].selections = [...this.data.roster.forces[0].selections, ...group.units]
  }

  serialize(){
    return this.data
  }
}