import App from "./App";
import RosterDisplay, {roster_loader, UnitList} from "./components/RosterDisplay";
import {unit_loader, UnitDisplay} from "./components/Datasheet";
import StratagemsView, {stratagems_loader} from "./components/Stratagems";
import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {LoginPage, RegisterPage} from "./components/Auth";
import {Auth} from "./auth";
import Home, {user_profile_loader} from "./components/Home";
import {MissionCardDeck, missions_loader} from "./components/MissionCardDeck";


const Routes = () => {
  // Routes accessible only to authenticated users
  const routes = [
    {
      path: "/login",
      element: <LoginPage/>,
    },
    {
      path: "/sign_up",
      element: <RegisterPage/>,
    },
    {
      path: "/logout",
      loader: Auth.sign_out,
      element: <p>Goodbye</p>,
    },
    {
      path: "/",
      element: <App/>,
      loader: Auth.require_auth(() => {return null}),
      children: [
        {
          index: true,
          loader: Auth.require_auth(user_profile_loader),
          element: <Home/>,

        },
        {
          path: "rosters/:roster_id",
          loader: Auth.require_auth(roster_loader),
          element: <RosterDisplay/>,
          children: [
            {
              index: true,
              element: <UnitList/>,
              loader: Auth.require_auth(roster_loader),
            },
            {
              path: "units/:unit_id",
              loader: Auth.require_auth(unit_loader),
              element: <UnitDisplay/>,
            },
            {
              path: "stratagems",
              element: <StratagemsView/>,
              loader: Auth.require_auth(stratagems_loader),
            },
            {
              path: "missions",
              element: <MissionCardDeck/>,
              loader: missions_loader,
            }
          ]
        },
      ],
    }
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter(routes);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;