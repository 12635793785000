import {useLoaderData, useNavigate} from "react-router-dom";
import {API} from "../api";
import List from '@mui/material/List';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Button,
  IconButton,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useState} from "react";


export async function user_profile_loader() {
  const {username} = await API.my_profile()
  const rosters = await API.list_rosters()
  return {username, rosters}
}


export default function Home() {
  const {username} = useLoaderData()
  return (
    <div>
      <p>Hello, {username}</p>
      <RostersList/>
    </div>
  )
}


function RostersList() {
  const {rosters} = useLoaderData()
  const navigate = useNavigate()

  const onRosterSelect = (roster_list_item) => {
    navigate("rosters/" + roster_list_item.id)
  }

  const onRosterFileUpload = async (files) => {
    await API.upload_roster(files[0])
  }

  return (
    <List>
      {rosters.map(r => <RosterListItem roster={r} onSelect={() => onRosterSelect(r)}/>)}
      <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon/>}>
        Upload files
        <InvisibleFileUploader onFileSelect={(file) => onRosterFileUpload(file)}/>
      </Button>
    </List>
  )
}


function InvisibleFileUploader({onFileSelect}) {
  return (
    <input
      type="file"
      onChange={(event) => onFileSelect(event.target.files)}
      style={{display: 'none'}}
    />
  )
}


function RosterListItem({roster, onSelect}){
  const ITEM_HEIGHT = 48;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    await API.delete_roster(roster.id)
    window.location.reload();
  };

  return (
    <ListItemButton key={roster.id} sx={{ "display": "flex", "flex-direction": "row"}}>
      <ListItemText onClick={() => onSelect(roster)} primary={roster.name}/>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          },
        }}
      >
          <MenuItem selected={false} onClick={handleDelete}>Delete</MenuItem>
      </Menu>

    </ListItemButton>
  )
}
