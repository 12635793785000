import "./MissionCardDeck.css"
import {API} from "../api";
import {useLoaderData} from "react-router-dom";
import {useEffect, useState} from "react";
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import {IconButton} from "@mui/material";

export async function missions_loader() {
  return await API.get_mission_deck()
}

export function MissionCardDeck() {
  const [pinnedCards, setPinnedCards] = useState(JSON.parse(localStorage.getItem('pinnedCards')) || []);

  useEffect(() => {
    localStorage.setItem('pinnedCards', JSON.stringify(pinnedCards));
  }, [pinnedCards]);

  const cards = useLoaderData().reduce((acc, card) => {
    (acc[card.type] = acc[card.type] || []).push(card);
    return acc;
  }, {});

  const handlePin = (card) => {
    setPinnedCards(prev => {
      if (prev.includes(card)) {
        return prev.filter(c => c !== card);
      } else {
        return [...prev, card];
      }
    });
  };

  return (
    <div>
      {pinnedCards.map(m => <MissionCard mission={m} onPin={handlePin} pinned/>)}
      {Object.keys(cards).map(section_name =>
        <CardSection
          section_name={section_name}
          cards={cards[section_name].sort((a, b) => a.name.localeCompare(b.name))}
          onPin={handlePin}
          pinnedCards={pinnedCards}
        />
      )}
    </div>
  )
}

function CardSection({section_name, cards, onPin, pinnedCards}) {
  const [isOpen, setIsOpen] = useState(false)

  let color = ""
  if (section_name.includes("Primary") || section_name.includes("Rule"))
    color = " primary"
  if (section_name.includes("Secondary"))
    color = " secondary"
  if (section_name.includes("Gambit"))
    color = " gambit"

  return (
    <div>
      <div className={"MissionCardHeader" + color} onClick={() => setIsOpen(!isOpen)}>
        <h3>{section_name}</h3>
      </div>
      {isOpen && cards.map(m => <MissionCard mission={m} onPin={onPin} pinned={pinnedCards.includes(m)}/>)}
    </div>
  )
}

function MissionCard({mission, onPin, pinned}) {
  const [isOpen, setIsOpen] = useState(false)

  let color = ""
  if (mission.type.includes("Primary") || mission.type.includes("Rule"))
    color = " primary"
  if (mission.type.includes("Secondary"))
    color = " secondary"
  if (mission.type.includes("Gambit"))
    color = " gambit"

  return (
    <div className="MissionCard">
      <div className={"MissionCardHeader" + color}>
        <div  onClick={() => setIsOpen(!isOpen)} >
          <h5 className={"MissionType"}>{mission.type}</h5>
          <h4>{mission.name}</h4>
        </div>
        <IconButton onClick={() => onPin(mission)}>
          {pinned ? <PushPinOutlinedIcon/> : <PushPinIcon/>}
        </IconButton>
      </div>
      {isOpen && <p className="MissionCardText">{mission.text.split('\n').map((line, index) => <span key={index}>{line}<br/></span>)}</p>}
    </div>
  )
}