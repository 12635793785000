import axios from "axios";
import {Auth} from "./auth";

const client = axios.create({
  baseURL: "/api"
});

client.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized access - HTTP 401');
      Auth.sign_out()
    }
    return Promise.reject(error);
  }
);

export const API = {
  set_token: (token) => {
    client.defaults.headers.common['Authorization'] = token;
  },
  sign_in: async (username, password) => {
    return await client.post('/auth/token',
      new URLSearchParams({username: username, password: password}))
      .then(res => res.data)
  },
  sign_up: async (username, password) => {
    return await client.post('/auth/register', {username: username, password: password})
      .then(resp => resp.data)
  },
  my_profile: async () => {
    return await client.get('/users/me')
      .then(resp => resp.data)
  },
  list_rosters: async () => {
    return await client.get('/user/rosters/')
      .then(resp => resp.data)
  },
  get_roster: async (roster_id) => {
    return await client.get('/user/rosters/' + roster_id)
      .then(resp => resp.data)
  },
  upload_roster: async (roster_file) => {
    const formData = new FormData();
    formData.append('roster_file', roster_file);
    return await client.post('/user/rosters/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(resp => resp.data)
  },
  delete_roster: async (roster_id) => {
    return await client.delete('/user/rosters/' + roster_id)
      .then(resp => resp.data)
  },
  update_roster: async (new_roster) => {
    return await client.put('/user/rosters/', new_roster)
      .then(resp => resp.data)
  },
  get_detachment: async (detachment_name) => {
    return await client.get('/rules/detachment/', {params: {name: detachment_name}})
      .then(resp => resp.data)
  },
  get_core_stratagems: async () => {
    return await client.get('/rules/core/stratagems')
      .then(resp => resp.data)
  },
  get_mission_deck: async () => {
    return await client.get('/rules/leviathan/mission_deck')
      .then(resp => resp.data)
  }
}