import './BattleDatasheetsView.css';
import './UnitList.css';
import './Menu.css';
import MenuIcon from '@mui/icons-material/Menu';
import {Link, Outlet, useLoaderData, useLocation, useNavigate} from "react-router-dom";
import {API} from "../api";
import {createContext, useContext, useEffect, useState} from "react";
import {Drawer, IconButton, ListItemButton, Menu, MenuItem} from "@mui/material";
import List from "@mui/material/List";
import {Roster} from "../models/roster";
import MoreVertIcon from "@mui/icons-material/MoreVert";


export async function roster_loader({params}) {
  const roster_data = await API.get_roster(params.roster_id)
  return new Roster(roster_data)
}


function RosterDisplayMenu({roster_id}) {
  const location = useLocation();
  const navigate = useNavigate()
  const roster_link = "/rosters/" + roster_id
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <div className={"topMenu sororitas"}>
      <IconButton onClick={() => setIsDrawerOpen(true)} size="large"><MenuIcon/></IconButton>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} >
        <List>
          <ListItemButton onClick={() => navigate("/")}>Home</ListItemButton>
          {//<ListItemButton onClick={() => navigate("/logout")}>Log Out</ListItemButton>
          }
        </List>
      </Drawer>
      {roster_id &&
        <Link className={`topMenuItem ${location.pathname === "/" ? "active" : ""}`} to={roster_link}>
          <img src="/menu_icons/forces.png" alt="Forces"/>
        </Link>
      }
      {roster_id &&
        <Link className={`topMenuItem ${location.pathname === "/stratagems" ? "active" : ""}`} to={roster_link + "/stratagems"}>
          <img src="/menu_icons/stratagems.png" alt="Stratagems"/>
        </Link>
      }
      <Link className={`topMenuItem ${location.pathname === "/stratagems" ? "active" : ""}`} to={roster_link + "/missions"}>
        <img src="/menu_icons/missions.png" alt="Missions"/>
      </Link>
    </div>
  )
}


export default function RosterDisplay() {
  const roster = useLoaderData();
  return (
    <div>
      <RosterDisplayMenu roster_id={roster.getId()}/>
      <Outlet/>
    </div>
  );
}


const UnitListContext = createContext({
  context: null,
  startJoin: () => {},
  startSplit: () => {},
  startRename: () => {}
})


export function UnitList(){
  const roster = useLoaderData();
  const units = roster.getAllUnits()

  const [unitContext, setUnitContext] = useState({action: "view"})

  const [deadUnitIds, setDeadUnitIds] = useState(() => {
  const storedDeadUnitIds = JSON.parse(localStorage.getItem('deadUnitIds'));
  return storedDeadUnitIds || [];
});

  let navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('deadUnitIds', JSON.stringify(deadUnitIds));
    const storedDeadUnitIds = JSON.parse(localStorage.getItem('deadUnitIds'));
    if (storedDeadUnitIds) {
      setDeadUnitIds(storedDeadUnitIds);
    }
  }, [deadUnitIds]);

  const cancelContext = () => {
    setUnitContext({action: "view"})
  }

  const confirmActions = (args) => {
    if (unitContext.action === "join") {
      roster.joinUnits(unitContext.units)
      API.update_roster(roster.serialize()).then(window.location.reload())
    }
    if (unitContext.action === "split") {
      roster.splitGroup(unitContext.unit)
      API.update_roster(roster.serialize()).then(window.location.reload())
    }
    if (unitContext.action === "rename") {
      unitContext.unit.name = args
      API.update_roster(roster.serialize()).then(window.location.reload())
    }
  }

  const _startJoin = (unit) => {
    setUnitContext({
      action: "join",
      units: [unit]
    })
  }

  const _startSplit = (unit) => {
    setUnitContext({
      action: "split",
      unit: unit
    })
  }


  const _startRename = (unit) => {
    setUnitContext({
      action: "rename",
      unit: unit
    })
  }

  const markUnitDead = (unit) => {
    if (deadUnitIds.includes(unit.id))
      setDeadUnitIds(deadUnitIds.filter(u => u !== unit.id))
    else
      setDeadUnitIds([...deadUnitIds, unit.id])
  }

  const handleUnitClick = (unit) => {
    if (unitContext.action === "join")
      if (unitContext.units.includes(unit))
        setUnitContext({action: "join", units: unitContext.units.filter(u => u !== unit)})
      else
        setUnitContext({action: "join", units: [...unitContext.units, unit]})
    else
      navigate(`units/${unit.id}`)
  }
  return (
    <div className="UnitList sororitas">
      <UnitListContext.Provider value={{context: unitContext, startJoin: _startJoin, startSplit: _startSplit,
        startRename: _startRename}}>
        {["split", "join"].includes(unitContext.action) &&
          <div>
            {unitContext.action === "join" && <p> Join {unitContext.units.map(u => u.name).join(', ')} ?</p>}
            {unitContext.action === "split" && <p> Split {unitContext.unit.name} into {unitContext.unit.units.map(u => u.name).join(', ')} ?</p>}
            <button onClick={cancelContext}> Cancel</button>
            <button onClick={confirmActions}>Confirm</button>
          </div>
        }
        {unitContext.action === "rename" && <UnitRenameForm unit={unitContext.unit} onConfirm={confirmActions}/>}
        {["view", "join"].includes(unitContext.action) &&
          units
            .filter(u => !deadUnitIds.includes(u.id))
            .map(u => <UnitListItem unit={u} isDead={false} onUnitClick={() => handleUnitClick(u)} onDeadClick={markUnitDead}/>)
        }
        {["view", "join"].includes(unitContext.action) &&
          units
            .filter(u => deadUnitIds.includes(u.id))
            .map(u => <UnitListItem unit={u} isDead={true} onUnitClick={() => handleUnitClick(u)} onDeadClick={markUnitDead}/>)
        }
      </UnitListContext.Provider>
    </div>
  )
}


function UnitListItem({unit, isDead, onUnitClick, onDeadClick}) {
  const ITEM_HEIGHT = 48;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {startJoin, startSplit, startRename} = useContext(UnitListContext)

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation()
  };
  const handleMenuClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation()
  };

  const handleJoin = (e) => {
    startJoin(unit)
    handleMenuClose(e)
    e.stopPropagation()
  }

  const handleSplit = (e) => {
    startSplit(unit)
    handleMenuClose(e)
    e.stopPropagation()
  }

  const handleRename = (e) => {
    startRename(unit)
    handleMenuClose(e)
    e.stopPropagation()
  }

  const handleDead = (e) => {
    onDeadClick(unit)
    e.stopPropagation()
  }

  return (
    <div className={`UnitListItem${isDead ? " dead" : " alive"}`} onClick={onUnitClick}>
        <p>{unit.name}</p>
        <div className="UnitButtonGroup">
          <div className="UnitDeadButton" onClick={(e) => handleDead(e)}>
            <img src={isDead ? "/menu_icons/undo.png" : "/menu_icons/skull.png"} alt="DEAD"/>
          </div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMenuOpen}
          >
            <MoreVertIcon/>
          </IconButton>
        </div>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          },
        }}
      >
          <MenuItem selected={false} onClick={handleJoin}>Join</MenuItem>
          {unit.type === "join_group" && <MenuItem selected={false} onClick={handleSplit}>Split</MenuItem>}
          <MenuItem selected={false} onClick={handleRename}>Rename</MenuItem>
      </Menu>

    </div>
  )
}


function UnitRenameForm({unit, onConfirm}){
  const [newName, setNewName] = useState()

  return(
    <div>
      <p>Rename {unit.name} to:</p>
      <input value={newName} onChange={(e) => setNewName(e.target.value)}/>
      <button>Cancel</button>
      {newName && <button onClick={() => onConfirm(newName)}>OK</button>}
    </div>
  )
}
