import {redirect} from "react-router-dom";
import {API} from "./api";


export const Auth = {
  sign_in: async (username, password) => {
    await API.sign_in(username, password)
      .then(({access_token, token_type, expires_at}) => {
        localStorage.setItem('access_token', access_token)
        localStorage.setItem('token_type', token_type)
        localStorage.setItem('expires_at', expires_at)
        API.set_token(`${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`)
      })
  },
  sign_out: () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('token_type')
    localStorage.removeItem('expires_at')
    return redirect("/")
  },
  require_auth: (loader) => {
    return async (...args) => {
      const isAuthenticated = !!localStorage.getItem("access_token");
      const expires_at = new Date(localStorage.getItem("expires_at"))
      const now = new Date()
      if (!isAuthenticated ||  now > expires_at) {
        return redirect("/login");
      }
      API.set_token(`${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`)
      return loader(...args);
    }
  }
}